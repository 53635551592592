@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.neon-text-allsize {
  animation: neon 0.5s infinite alternate;
}

@keyframes neon {
  0% {
    text-shadow: 0 0 10px #ff1493, 0 0 15px #ff1493, 0 0 25px #ff1493;
  }
  100% {
    text-shadow: 0 0 20px #ff1493, 0 0 40px #ff1493, 0 0 60px #ff1493;
  }
}

::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.636);
}

/* Track */
::-webkit-scrollbar-track {
  background: rgb(255, 255, 255);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}


/* table header */
th{
  background: #000000 !important;
  color: white !important;
}

/* hides scroll bar when not needed */
.ant-table-body{
  overflow-y: auto !important;
}

/* table rows */
.ant-table-cell{
  border-bottom: 1px solid rgba(34, 211, 238, 0.4) !important;
  background-color: rgb(0,0,0,1);
  color: white;
}


/* turns off white dividers on row headers */
.ant-table-cell::before{
  background-color: black !important;
}

.ant-table-cell-scrollbar{
  background-color: rgb(0,0,0,1) !important;
  box-shadow: none !important;
}

/* makes DATE column in matchHistory black */
.ant-table-column-sort{
  border-bottom: 1px solid rgba(34, 211, 238, 0.4) !important;
  background-color: rgb(0,0,0,1) !important;
  color: white;
}


/* needed to prevent AntD row highlight overriding desired css*/
table,
tr,
thead {
    background: #000000 !important;
}

table:hover,
tr:hover,
thead:hover {
    background: #20293c !important;
}

.ant-table-tbody > tr.ant-table-row:hover > td {
    background: none !important;
}


.bg-black-opacity-50 {
  background-color: rgba(
    0,
    0,
    0,
    0.5
  ); /* Use rgba to control background opacity */
}

.bg-black-opacity-70 {
  background-color: rgba(
    0,
    0,
    0,
    0.7
  ); /* Use rgba to control background opacity */
}

.bg-black-opacity-90 {
  background-color: rgba(
    0,
    0,
    0,
    0.9
  ); /* Use rgba to control background opacity */
}

/* Set the text inside bg div to 100% opacity */
.bg-black-opacity-70 > .block {
  opacity: 1;
}

.custom-radio {
  width: 20px;
  height: 20px;
  appearance: none;
  -webkit-appearance: none;
  border: 1px solid #ffffff;
  border-radius: 4px;
  outline: none;
  cursor: pointer;
  margin-right: 5px;
}

.custom-radio:checked {
  background-color: #1bbe6f; /* Change background color when selected */
}

.select-search-select {
  height: 14em;
}
.select-search-container {
  width: 100% !important;
}
@media screen and (max-width: 640px) {
  .ant-table table {
    font-size: 10px;
  }
  .ant-tag {
    font-size: 10px;
  }
}

@media screen and (min-width: 850px) {
  .neon-text {
    animation: neon 0.5s infinite alternate;
  }
}

.vertical-timeline-element-icon{
  opacity: 1.0;
  box-shadow: 0 0 0 4px #11bdd7, inset 0 2px 0 rgba(0,0,0,.08), 0 3px 0 4px rgba(0,0,0,.05) !important;
}
.vertical-timeline-element-date{
  opacity: 0.9 !important;
  text-shadow: 3px 2px 2px black !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
